/* global settings */

import React, { Component, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';

import Context from '../context';

function Signup() {
    const [ searchParams ] = useSearchParams();
    const context = useContext(Context);

    const code = searchParams.get("code");

    function onSubmit(e) {
        const elements = e.currentTarget.elements;
        const username = elements.username.value;
        const password = elements.password.value;
        const confirmPassword = elements.confirmPassword.value;
        if (password !== confirmPassword || !username) {
            // TODO: Show error
            e.preventDefault();
            alert("Username or password is invalid");
        }
    }

    return (
        <div className="container d-flex flex-column justify-content-center" style={styles.container}>
            <form action={context.dal.apiUrl + "/signup/account"} method="post" onSubmit={onSubmit}>
                <input type="hidden" name="code" value={code}></input>
                <div className="form-group row">
                    <label htmlFor="username" className="col-sm-2 col-form-label offset-sm-3">Username</label>
                    <div className="col-sm-4">
                        <input id="username" className="form-control" type="text" name="username"></input>
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="password" className="col-sm-2 col-form-label offset-sm-3">Password</label>
                    <div className="col-sm-4">
                        <input id="password" className="form-control" type="password" name="password"></input>
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="confirmPassword" className="col-sm-2 col-form-label offset-sm-3">Confirm Password</label>
                    <div className="col-sm-4">
                        <input id="confirmPassword" className="form-control" type="password" name="confirmPassword"></input>
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-sm-4 offset-sm-5">
                        <div className="d-flex justify-content-start">
                            <button type="submit" className="btn btn-primary">Create Account</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default Signup;

const styles = {
    container: {
        minHeight: "0",
        overflowY: "auto",
        flexGrow: "1"
    },
    button: {
        cursor: "pointer"
    }
};
