import React, { Component, useContext } from 'react';
import { inject, observer } from 'mobx-react';

import Header from './Header';

import Context from '../../context';

function Playlist(props) {
    const context = useContext(Context);

    function onItemAction(e) {
        const element = e.currentTarget;
        const { key } = element.dataset;
        const input = document.getElementById(`url-${key}`);
        if (!input) {
            return;
        }

        input.select();
        document.execCommand("copy");
    }

    function onEditPlaylist(e) {
        let nextMode = "";
        switch (this.state.mode) {
            case "play":
                nextMode = "edit";
                break;
            case "":
            case "edit":
            default:
                nextMode = "play";
                break;
        }
        this.setState({ mode: nextMode, editKey: -1 });
    }

    function onAddTrack(e) {
        this.setState((state, props) => {
            return { adding: !state.adding };
        });
    }

    function onEditTrack(e) {
        e.preventDefault();
        const element = e.currentTarget;
        const key = parseInt(element.dataset.key, 10);
        this.setState({ editKey: key });
    }

    function onRemoveTrack(removeTrack) {
        const username = this.props.username;
        const id = this.props.id;
        return (e) => {
            e.preventDefault();
            const element = e.currentTarget;
            const key = element.dataset.key;
            const post = this.props.tracks[key];
            const track = post.link;
            /*removeTrack({
                variables: { id, track },
                refetchQueries: [{
                    query: GQLQueries.getStation,
                    variables: { username, id }
                }]
            });*/
        }
    }

    function onClickSave(addTrack) {
        const username = this.props.username;
        const id = this.props.id;
        return (e) => {
            e.preventDefault();
            const track = this.trackInput ? this.trackInput.value : null;
            const title = this.titleInput ? this.titleInput.value : null;
            if (!track) {
                return;
            }

            /*addTrack({
                variables: { id, track, title },
                refetchQueries: [{
                    query: GQLQueries.getStation,
                    variables: { username, id }
                }]
            }).then((track) => {
                console.debug("Added track", track);
            }).catch((error) => {
                console.error("Error while adding track", error);
            });*/
    
            this.setState({ adding: false });
        }
    }

    function onClickCancel(e) {
        e.preventDefault();
        this.setState({ adding: false });
    }

    function onEditClickCancel(e) {
        e.preventDefault();
        this.setState({ editKey: -1 });
    }

    function onEditSaveChanges(editTrack) {
        const username = this.props.username;
        const id = this.props.id;
        return (e) => {
            e.preventDefault();
            const element = e.currentTarget;
            const key = element.dataset.key;
            const post = this.props.tracks[key];

            const title = this.editElementTitle ? this.editElementTitle.value : null;
            const link = this.editElementLink ? this.editElementLink.value : null;

            if (!link) {
                return;
            }

            const variables = {
                id,
                track: post.link,
                newTrack: link,
                newTitle: title
            };

            /*editTrack({
                variables,
                refetchQueries: [{
                    query: GQLQueries.getStation,
                    variables: { username, id }
                }]
            }).then(result => {
                this.setState({ editKey: -1 });
            });*/
        };
    }

    function onClickElement(e) {
        context.play();
    }

    function renderElement(element, key) {
        return (
            <li key={key} className="media">
                <div className="media-body">
                    <hr />
                    <div className="d-flex justify-content-between align-items-baseline">
                        <a href={`#${key + 1}`} onClick={onClickElement}><h5>{element.title}</h5></a>
                    </div>
                </div>
            </li>
        );
    }

    const { station, tracks, username } = context;
    if (!station || !tracks) {
        return <div>Loading</div>;
    }

    return (
        <div>
            <hr />
            <ul className="list-unstyled">
                <Header station={station} username={username} />
                { tracks.map(renderElement) }
            </ul>
            <hr/>
        </div>
    );
}

export default observer(Playlist);
