import React, { useState, useContext } from 'react';
import { useLoaderData, useParams } from 'react-router-dom';

import Context from '../../context';

function Tracks() {
    const initialTracks = useLoaderData();
    const [ tracks, setTracks ] = useState(initialTracks);

    const [ selectIndex, setSelectIndex ] = useState(initialTracks.length + 1);
    const [ title, setTitle ] = useState("");
    const [ resource, setResource ] = useState("");

    const { username, id } = useParams();
    const context = useContext(Context);

    function onSelectChange(e) {
        setSelectIndex(e.currentTarget.value);
    }

    function onInputChange(e) {
        const value = e.currentTarget.value;
        switch (e.currentTarget.name) {
            case "title":
                setTitle(value);
                break;
            case "link":
                setResource(value);
                break;
        }
    }

    function onClickAdd(e) {
        e.preventDefault();
        context.dal.addTrack(username, id, resource, title, selectIndex).then(json => {
            const newTracks = Array.from(tracks);
            newTracks.splice(json.index - 1, 0, json);
            setTracks(newTracks);
            setSelectIndex(newTracks.length + 1);
            setTitle("");
            setResource("");
        }).catch(err => {
            console.error("Error adding track", err);
        });
    }

    function onClickSave(e) {
        e.preventDefault();
        context.dal.editTrack(username, id, selectIndex, resource, title).then(json => {
            const newTracks = Array.from(tracks);
            newTracks[json.index - 1].resource = json.resource;
            newTracks[json.index - 1].title = json.title;
            setTracks(newTracks);
            setSelectIndex(newTracks.length + 1);
            setTitle("");
            setResource("");
        });
    }

    function onClickEdit(e) {
        e.preventDefault();
        const target = e.currentTarget;
        const trackIndex = target.dataset.index;
        const track = tracks[trackIndex];

        setSelectIndex(track.index);
        setTitle(track.title);
        setResource(track.resource);
    }

    function onClickRemove(e) {
        e.preventDefault();
        const index = e.currentTarget.dataset.index;
        const track = tracks[index];
        context.dal.removeTrack(username, id, track.resource).then(() => {
            const newTracks = Array.from(tracks);
            newTracks.splice(index, 1);
            setTracks(newTracks);
            setSelectIndex(newTracks.length + 1);
        }).catch(err => {
            console.error("Error removing track", err);
        });
    }

    function renderSelect() {
        return (
            <select name="index" value={selectIndex} onChange={onSelectChange}>
                {Array(tracks.length + 1).fill(0).map((value, index) => {
                    return <option key={index} value={index + 1}>{index + 1}</option>
                })}
            </select>
        );
    }

    function renderRow(track, index) {
        return (
            <div className="row" key={index}>
                <div className="col-lg-1">{index + 1}</div>
                <div className="col-lg-4">{track.title}</div>
                <div className="col-lg-5"><a href={track.resource} target="_blank">{track.resource}</a></div>
                <div className="col-lg-2">
                    <button className="btn btn-link" data-index={index} onClick={onClickEdit}>Edit</button>
                    <button className="btn btn-link" data-index={index} onClick={onClickRemove}>Remove</button>
                </div>
                <hr />
            </div>
        )
    }

    function render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-lg-1">#</div>
                    <div className="col-lg-4">Title</div>
                    <div className="col-lg-5">Link</div>
                    <div className="col-lg-2">Actions</div>
                    <hr />
                </div>
                {tracks.map(renderRow)}
                <form>
                    <div className="row">
                        <div className="col-lg-1">{renderSelect()}</div>
                        <div className="col-lg-4"><input type="text" name="title" placeholder="Optional" value={title} onChange={onInputChange}></input></div>
                        <div className="col-lg-5"><input type="text" name="link" placeholder="Required" value={resource} onChange={onInputChange}></input></div>
                        <div className="col-lg-2">
                        <button type="submit" className="btn btn-link" data-action="add" onClick={onClickAdd}>Add</button>
                        <button type="submit" className="btn btn-link" data-action="save" onClick={onClickSave}>Save</button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }

    function renderMessage(message) {
        return (
            <div className="container">
                <div className="col-lg">
                    <div className="mt-3">
                        <p>{message}</p>
                    </div>
                </div>
            </div>
        )
    }

    const loggedInUser = context.username;
    if (!loggedInUser) {
        return renderMessage("Not logged in");
    } else if (loggedInUser !== username) {
        return renderMessage("Forbidden");
    } else {
        return render();
    }
}

function tracksLoader(context) {
    return async ({ request, params }) => {
        const { username, id } = params;
        return await context.dal.fetchTracks(username, id);
    }
}

export default Tracks;
export {
    tracksLoader
};