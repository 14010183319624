class Dal {
    apiUrl = "https://friend.srad.io/api"

    constructor() {
        if (process.env.REACT_APP_API_URL) {
            this.apiUrl = process.env.REACT_APP_API_URL;
        }
    }

    fetchOptions(method = "GET") {
        return {
            method,
            credentials: "include",
            headers: {
                'Content-Type': 'application/json'
            }
        };
    }

    async fetchCatalog() {
        const response = await fetch(`${this.apiUrl}/stations`, this.fetchOptions());
        if (!response || !response.ok) {
            return Promise.reject({ "error": response.status });
        }

        return await response.json();
    }

    async fetchStations(username) {
        if (!username) {
            throw "username";
        }

        const response = await fetch(`${this.apiUrl}/user/${username}/stations`, this.fetchOptions());
        if (!response || !response.ok) {
            return Promise.reject({ "error": response.status });
        }

        return await response.json();
    }
    
    async fetchStation(username, canonical) {
        if (!username) {
            throw "username";
        }
        if (!canonical) {
            throw "canonical";
        }

        const response = await fetch(`${this.apiUrl}/user/${username}/station/${canonical}`, this.fetchOptions());
        if (!response || !response.ok) {
            return Promise.reject({ "error": response.status });
        }

        return await response.json();
    }

    async createStation(username, name, canonical, visibility = true) {
        if (!username) {
            throw "username";
        }
        if (!name) {
            throw "name";
        }
        if (!canonical) {
            throw "canonical";
        }

        const options = this.fetchOptions("POST");
        options.body = JSON.stringify({ 
            name,
            url: canonical,
            public: visibility
        });

        const response = await fetch(`${this.apiUrl}/user/${username}/stations`, options);
        if (!response || !response.ok) {
            return Promise.reject({ "error": response.status });
        }

        return await response.json();
    }

    async updateStation(username, station, name, canonical, isPublic) {
        if (!username) {
            throw "username";
        }
        if (!station) {
            throw "station";
        }

        const options = this.fetchOptions("PUT");
        options.body = JSON.stringify({ 
            name,
            url: canonical,
            public: isPublic
        });

        const response = await fetch(`${this.apiUrl}/user/${username}/station/${station}`, options);
        if (!response || !response.ok) {
            return Promise.reject({ "error": response.status });
        }

        return await response.json();
    }

    async deleteStation(username, station) {
        if (!username) {
            throw "username";
        }

        if (!station) {
            throw "station";
        }

        const response = await fetch(`${this.apiUrl}/user/${username}/station/${station}`, this.fetchOptions("DELETE"));
        if (!response || !response.ok) {
            return Promise.reject({ "error": response.status });
        }

        return true;
    }
    
    async fetchTracks(username, canonical) {
        if (!username) {
            throw "username";
        }
        if (!canonical) {
            throw "canonical";
        }

        const response = await fetch(`${this.apiUrl}/user/${username}/station/${canonical}/tracks`, this.fetchOptions());
        if (!response || !response.ok) {
            return Promise.reject({ "error": response.status });
        }

        return await response.json();
    }

    async addTrack(username, canonical, resource, title, index = -1) {
        if (!username) {
            throw "username";
        }
        if (!canonical) {
            throw "canonical";
        }
        if (!resource) {
            throw "resource";
        }

        const options = this.fetchOptions("POST");
        options.body = JSON.stringify({
            resource,
            title,
            index
        });

        const response = await fetch(`${this.apiUrl}/user/${username}/station/${canonical}/tracks`, options);
        if (!response || !response.ok) {
            return Promise.reject({ "error": response.status });
        }

        return await response.json();
    }

    async editTrack(username, canonical, index, resource, title) {
        if (!username) {
            throw "username";
        }

        if (!canonical) {
            throw "canonical";
        }

        if (!index) {
            throw "index";
        }

        if (!resource && !title) {
            throw "missing resource or title";
        }

        const options = this.fetchOptions("PATCH");
        options.body = JSON.stringify({
            resource,
            title
        });

        const response = await fetch(`${this.apiUrl}/user/${username}/station/${canonical}/tracks/${index}`, options);
        if (!response || !response.ok) {
            return Promise.reject({ "error": response.status });
        }

        return await response.json();
    }

    async removeTrack(username, canonical, resource) {
        if (!username) {
            throw "username";
        }
        if (!canonical) {
            throw "canonical";
        }
        if (!resource) {
            throw "resource";
        }

        const options = this.fetchOptions("DELETE");
        options.body = JSON.stringify({
            resource
        });

        const response = await fetch(`${this.apiUrl}/user/${username}/station/${canonical}/tracks`, options);
        if (!response || !response.ok) {
            return Promise.reject({ "error": response.status });
        }

        return true;
    }
    
    async fetchResource(resource) {
        if (!resource) {
            throw "resource";
        }

        const response = await fetch(`${this.apiUrl}/oembed?url=${encodeURIComponent(resource)}`, this.fetchOptions());
        if (!response || !response.ok) {
            return Promise.reject({ "error": response.status });
        }

        return await response.json();
    }

    async getLoggedInUser() {
        const response = await fetch(`${this.apiUrl}/me`, this.fetchOptions());
        if (!response || !response.ok) {
            return Promise.reject({ "error": response.status });
        }

        return await response.json();
    }

    async logout() {
        const response = await fetch(`${this.apiUrl}/logout`, this.fetchOptions());
        if (!response || !response.ok) {
            return Promise.reject({ "error": response.status });
        }

        return true;
    }
}

export default Dal;
