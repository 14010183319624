import React, { useContext } from 'react';
import {
    RouterProvider,
    createBrowserRouter,
} from 'react-router-dom';

import ReactContext from '../../context';

import AddStation from '../AddStation';
import AddTrack, { addTrackLoader } from '../Tracks/AddTrack';
import Catalog, { catalogLoader } from '../Catalog';
import Container from '../Container';
import EditStation, { editStationLoader } from '../EditStation';
import Landing from '../Landing';
import Login from '../Login';
import Logout from '../Logout';
import Signup from '../Signup';
import Station, { stationLoader } from '../Station';
import Stations, { stationsLoader } from '../Stations';
import Tracks, { tracksLoader } from '../Tracks';

/*
import Learn from './navigation/Learn';

import Profile from './navigation/Profile';
import LoginModal from './components/LoginModal';
import ShareModal from './components/ShareModal';
*/

import './App.css';

export default function App({ context, createRouter = createBrowserRouter, routerOpts = {} }) {
    const router = createRouter(createRoutes(context), routerOpts);
    return {
        app: (
            <ReactContext.Provider value={context}>
                <RouterProvider router={router}/>
            </ReactContext.Provider>
        ),
        router
    };
}

function createRoutes(context) {
    return [
        {
            path: "/",
            element: <Container />,
            children: [
                {
                    index: true,
                    element: <Landing />
                },
                {
                    path: "catalog",
                    element: <Catalog />,
                    loader: catalogLoader(context)
                },
                {
                    path: "login",
                    element: <Login />
                },
                {
                    path: "logout",
                    element: <Logout />
                },
                {
                    path: "signup",
                    element: <Signup />
                },
                {
                    path: "stations",
                    element: <Stations />,
                    loader: stationsLoader(context)
                },
                {
                    path: "stations/add",
                    element: <AddStation />
                },
                {
                    id: "station",
                    path: "stations/:username/:id",
                    element: <Station />,
                    loader: stationLoader(context)
                },
                {
                    path: "stations/:username/:id/edit",
                    element: <EditStation />,
                    loader: editStationLoader(context)
                },
                {
                    path: "stations/:username/:id/tracks",
                    element: <Tracks />,
                    loader: tracksLoader(context)
                },
                {
                    path: "tracks/add",
                    element: <AddTrack />,
                    loader: addTrackLoader(context)
                }
            ]
        }
    ];
}
