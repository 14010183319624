import React, { useContext } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { observer } from 'mobx-react';

import Context from '../context';

function EditStation() {
    const { username, id } = useParams();
    const context = useContext(Context);
    const navigate = useNavigate();

    function onSubmit(username, id) {
        return (e) => {
            e.preventDefault();
            const target = e.currentTarget;
            context.dal.updateStation(username, id, target.elements["title"].value, target.elements["url"].value, target.elements["public"].checked).then(() => {
                navigate("/stations");
            }).catch((err) => {
                console.error("Error updating station", err);
            });
        };
    }

    function onClickDelete(username, id) {
        return (e) => {
            e.preventDefault();
            context.dal.deleteStation(username, id).then(() => {
                navigate("/stations");
            }).catch(() => {
                console.error("Error deleting station");
            });
        };
    }
    
    function onClickCancel(e) {
        navigate(-1);
    }

    function renderForm(username, title, url, isPublic) {
        return (
            <div className="container d-flex flex-column justify-content-center" style={styles.container}>
                <form onSubmit={onSubmit(username, url)}>
                    <div className="form-group">
                        <label htmlFor="nameInput">Station Name</label>
                        <input id="nameInput" className="form-control" type="input" name="title" defaultValue={title}></input>
                    </div>
                    <div className="form-group">
                        <label htmlFor="urlInput">Station URL</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">/stations/{username}/</span>
                            </div>
                            <input id="urlInput" className="form-control" type="text" name="url" defaultValue={url}></input>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" name="public" id="visibilityCheckbox" defaultChecked={isPublic}></input>
                            <label className="form-check-label" htmlFor="visibilityCheckbox">Public</label>
                        </div>
                        <button type="button" className="btn btn-danger mt-2" onClick={onClickDelete(username, url)}>Delete</button>
                    </div>
                    <div className="form-group">
                        <div className="col-sm-4 offset-sm-5">
                            <button type="submit" className="btn btn-primary">Save</button>
                            <button type="button" className="btn btn-default ml-2" onClick={onClickCancel}>Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }

    function renderMessage(message, className) {
        return (
            <div className="container d-flex flex-column justify-content-center" style={{ minHeight: "0", overflowY: "auto", flexGrow: "1" }}>
                <p className={`mx-auto ${className}`}>{message}</p>
            </div>
        );
    }

    const station = context.station;

    if (!station) return renderMessage("Loading", "text-secondary");
    if (!station.name) return renderMessage("Error", "text-danger");

    return renderForm(username, station.name, id, station.public);
}

const styles = {
    container: {
        minHeight: "0",
        overflowY: "auto",
        flexGrow: "1"
    }
};

function editStationLoader(context) {
    return async ({ request, params }) => {
        const { username, id } = params;
        return await context.loadStation(username, id);
    }
}

export default observer(EditStation);
export {
    editStationLoader
};