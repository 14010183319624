import React, { useContext, useState} from 'react';
import { observer } from 'mobx-react';

import Context from '../context';

import { useNavigate } from "react-router-dom";

function AddStation() {
    let [ discoverable, setDiscoverable ] = useState(true);
    let [ title, setTitle ] = useState("");
    let [ url, setUrl ] = useState("");

    const context = useContext(Context);
    const navigate = useNavigate();

    function handleInputChange(e) {
        const target = e.target;
        const name = target.name;

        switch (name) {
            case "discoverable":
                setDiscoverable(target.checked);
                break;
            case "title":
                setTitle(target.value);
                break;
            case "url":
                setUrl(target.value);
                break;
        }
    }

    function onSubmit(username) {
        return (e) => {
            e.preventDefault();
            const target = e.currentTarget;
            context.dal.createStation(username, target.elements["title"].value, target.elements["url"].value, target.elements["discoverable"].checked).then(data => {
                navigate(`/stations/${username}/${data.canonical}`);
            }).catch((err) => {
                console.error("Error updating station", err);
            });
        };
    }

    function onClickCancel(e) {
        navigate(-1);
    }

    if (!context.username) {
        return <p>Loading</p>;
    }

    const username = context.username;

    return (
        <div className="container d-flex flex-column justify-content-center" style={styles.container}>
            <form onSubmit={onSubmit(username)}>
                <div className="form-group">
                    <label htmlFor="nameInput">Station Name</label>
                    <input id="nameInput" className="form-control" type="input" name="title" onChange={handleInputChange}></input>
                </div>
                <div className="form-group">
                    <label htmlFor="urlInput">Station URL</label>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">/stations/{username}/</span>
                        </div>
                        <input id="urlInput" className="form-control" type="text" name="url" onChange={handleInputChange}></input>
                    </div>
                </div>
                <div className="form-group">
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" name="discoverable" id="visibilityCheckbox" onChange={handleInputChange} checked></input>
                        <label className="form-check-label" htmlFor="visibilityCheckbox">Public</label>
                    </div>
                </div>
                <div className="form-group">
                    <div className="col-sm-4 offset-sm-5">
                        <button type="submit" className="btn btn-primary">Create</button>
                        <button type="button" className="btn btn-default ml-2" onClick={onClickCancel}>Cancel</button>
                    </div>
                </div>
            </form>
        </div>
    );
}

const styles = {
    container: {
        minHeight: "0",
        overflowY: "auto",
        flexGrow: "1"
    }
};

export default AddStation;