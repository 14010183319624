import React, { useContext } from 'react';
import { Link, useLoaderData } from 'react-router-dom';

import { observer } from 'mobx-react';

import Context from '../context';

const defaultContent = (
    <div className="list-group-item list-group-item-action">
        <div className="d-flex justify-content-start">
            <span className="p-1 text-primary">No stations found</span>
        </div>
    </div>
);

function Catalog() {
    const context = useContext(Context);
    const stations = useLoaderData();

    function renderStation(element, index) {
        const { username } = context;

        const loggedIn = username?.length > 0;
        const owner = username === element.username;
        const stationUrl = `/stations/${element.User.username}/${element.canonical}`;

        const userLink = (
            <Link to={`/users/${element.User.username}`}>{element.User.username}</Link>
        );

        const stationLink = (
            <Link to={stationUrl}>{element.name}</Link>
        );

        const addon = (
            <div className="align-self-center">
                <Link to={stationUrl + "/edit"}>Edit</Link>
            </div>
        );

        return (
            <div className="list-group-item list-group-item-action" key={index}>
                <div className="d-flex justify-content-start">
                    <span className="p-1 text-primary">{stationLink}</span>
                    <span className="p-1">-</span>
                    <span className="p-1 text-primary">{userLink}</span>
                </div>
            </div>
        );
    }

    function renderStationList(stations) {
        if (!stations || stations.length < 1) {
            return defaultContent;
        }

        return stations.map(renderStation);
    }

    return (
        <div className="container">
            <div className="col-lg">
                <div className="mt-3">
                    <h1>Catalog</h1>
                    <div className="list-group">
                        {renderStationList(stations)}
                    </div>
                </div>
            </div>
        </div>
    );
}

function catalogLoader(context) {
    return async ({ request, params }) => {
        return await context.dal.fetchCatalog();
    };
}

export default observer(Catalog);
export {
    catalogLoader
};