import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useLoaderData, useParams, useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import Playlist from '../Playlist';
import Widget from '../Widget';

import Context from '../../context';

const styles = {
    container: {
        width: "100%"
    },
    content: {
        //overflowY: "auto",
        flexGrow: "1"
    }
};

function Station() {
    const { username, id } = useParams();
    const [ loaded, setLoaded ] = useState(false);
    const context = useContext(Context);

    useEffect(() => {
        if (!loaded) {
            context.setPlaylistIndex(context.trackIndex || 0);
            setLoaded(true);
        }
    });

    let resource = context.resource;
    let track = context.track;
    let key = context.trackIndex;

    const content = !track ? "No tracks" :
        <Widget key={key} resource={resource} track={track} />;

    return (
        <div className="d-flex align-items-stretch" style={styles.content}>
            <div className="container-fluid" style={styles.container}>
                <div className="row">
                    <Outlet />
                    <div className="col-sm-4 order-2 order-sm-1">
                        <Playlist username={username} station={id} />
                    </div>
                    <div className="col-sm-8 order-1 order-sm-2">
                        <hr />
                        <div style={{ backgroundColor: "white", width: "100%" }}>
                            {content}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function stationLoader(context) {
    return async ({ request, params }) => {
        const { username, id } = params;
        await Promise.all([
            context.loadStation(username, id),
            context.loadTracks(username, id)
        ]);
        context.stop();
    };
}

export default observer(Station);
export {
    stationLoader
};
