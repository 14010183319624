import React, { useContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import Context from '../context';

export default function Login() {
    const context = useContext(Context);
    let [ searchParams, setSearchParams ] = useSearchParams();
    let [ mode, setMode ] = useState("login");

    const redirect = searchParams.get("redirect");

    function toggleForm(e) {
        e.preventDefault();
        setMode(mode === "login" ? "signup" : "login");
    }

    function renderSignupForm(apiUrl) {
        return (
            <form method="post" action={apiUrl + "/signup"}>
                <div className="mb-3 row">
                    <label htmlFor="email" className="col-sm-2 col-form-label offset-sm-3 text-end">Email</label>
                    <div className="col-sm-4">
                        <input id="email" className="form-control" type="email" name="email"></input>
                    </div>
                </div>
                <div className="mb-3 row">
                    <div className="col-sm-4 offset-sm-5">
                        <div className="d-flex justify-content-start">
                            <button type="submit" className="btn btn-primary">Sign up</button>
                            <button type="submit" className="btn btn-link ml-2" onClick={toggleForm}>Log in</button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }

    function renderLoginForm(apiUrl) {
        return (
            <form action={apiUrl + "/login" + (redirect ? "?redirect=" + encodeURIComponent(redirect) : "")} method="post">
                <div className="mb-3 row">
                    <label htmlFor="username" className="col-sm-2 col-form-label offset-sm-3 text-end">Username</label>
                    <div className="col-sm-4">
                        <input id="username" className="form-control" type="text" name="username"></input>
                    </div>
                </div>
                <div className="mb-3 row">
                    <label htmlFor="password" className="col-sm-2 col-form-label offset-sm-3 text-end">Password</label>
                    <div className="col-sm-4">
                        <input id="password" className="form-control" type="password" name="password"></input>
                    </div>
                </div>
                <div className="mb-3 row">
                    <div className="col-sm-4 offset-sm-5">
                        <div className="d-flex justify-content-start">
                            <button type="submit" className="btn btn-primary">Log in</button>
                            <button type="submit" className="btn btn-link ml-2" onClick={toggleForm}>Sign up</button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }

    function renderMessage(type, message) {
        const className = `alert alert-${type}`;
        return (
            <div className={className} role="alert">
                {message}
            </div>
        );
    }

    function renderSignupMessage() {
        if (!searchParams.has("signup")) {
            return;
        }

        const signup = searchParams.get("signup");
        if (signup === "true") {
            return renderMessage("success", "Sign up email sent. Check your email for the confirmation code.");
        } else {
            return renderMessage("danger", "Sign up unsuccessful.");
        }
    }

    function render() {
        var content = null;
        switch (mode) {
            case "signup":
                content = renderSignupForm(context.dal.apiUrl);
                break;
            case "login":
            default:
                content = renderLoginForm(context.dal.apiUrl);
                break;
        }
        return (
            <div className="container d-flex flex-column justify-content-center" style={styles.container}>
                {renderSignupMessage()}
                {content}
            </div>
        );
    }

    return render();
}

const styles = {
    container: {
        minHeight: "0",
        overflowY: "auto",
        flexGrow: "1"
    },
    button: {
        cursor: "pointer"
    }
};
