import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Landing extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="d-flex flex-column justify-content-center" style={styles.container}>
                <div style={{ marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>
                    <h1>Friends Radio</h1>
                    <p>Listen to music your friends like <br/></p>
                    <Link to="/catalog"><button type="button" className="btn btn-secondary btn-block" style={styles.button}>Browse Stations</button></Link>
                </div>
            </div>
        );
    }
}

const styles = {
    container: {
        minHeight: "0",
        overflowY: "auto",
        flexGrow: "1"
    },
    button: {
        cursor: "pointer"
    }
};
