import React, { useContext, useEffect, useState } from 'react';

import { useLoaderData, useNavigate, useSearchParams } from 'react-router-dom';

import Context from '../../context';

const styles = {
    container: {
        minHeight: "0",
        overflowY: "auto",
        flexGrow: "1"
    }
};

function AddTrack() {
    const context = useContext(Context);
    const stations = useLoaderData();
    const [ searchParams ] = useSearchParams();

    const u = searchParams.get("u");

    if (!context.username) {
        let href = "/login";
        if (u) {
            const redirect = "tracks/add?u=" + u;
            href = "/login?redirect=" + encodeURIComponent(redirect);
        }
        return (
            <div className="container d-flex flex-column justify-content-center" style={styles.container}>
                <div className={`alert alert-danger`} role="alert">
                    <span>You must be logged in. <a href={href} className="alert-link">Click here</a> to login.</span>
                </div>
            </div>
        );
    }

    const [ url, setUrl ] = useState(u || "");
    const [ title, setTitle ] = useState("");
    const [ selected, setSelected ] = useState(stations[0].canonical);

    const navigate = useNavigate();

    function handleInputChange(e) {
        const target = e.target;
        switch (target.name) {
            case "url":
                setUrl(target.value);
                break;
            case "title":
                setTitle(target.value);
                break;
            case "id":
                setSelected(target.value);
                break;
        }
    }

    function onClickCancel(e) {
        this.props.history.goBack();
    }

    function onSubmit(username) {
        return async (e) => {
            e.preventDefault();

            if (!selected || !url) {
                console.warn("Could not add track");
                return;
            }

            try {
                const json = await context.dal.addTrack(context.username, selected, url, title);
                navigate(`/stations/${context.username}/${selected}`);
            } catch (e) {
                console.error("Could not add track", url, e);
            }
        };  
    }

    function renderStationOption(element, index) {
        return (
            <option key={`option-${index}`} value={element.canonical}>{element.name}</option>
        );
    }
    
    function renderForm(username, stations = []) {
        return (
            <div className="container d-flex flex-column justify-content-center" style={styles.container}>
                <form onSubmit={onSubmit(username)}>
                    <div className="form-group">
                        <label htmlFor="urlInput">Track URL</label>
                        <input id="urlInput" className="form-control" type="text" name="url" onChange={handleInputChange} placeholder="Required" value={url}></input>
                    </div>
                    <div className="form-group">
                        <label htmlFor="titleInput">Track Title</label>
                        <input id="titleInput" className="form-control" type="input" name="title" onChange={handleInputChange} placeholder="Optional" value={title}></input>
                    </div>
                    <div className="form-group">
                        <label htmlFor="stationSelect">Station</label>
                        <select id="stationSelect" className="form-control" name="id" onChange={handleInputChange} value={selected}>
                            {stations.map(renderStationOption)}
                        </select>
                    </div>
                    <div className="form-group">
                        <div className="col-sm-4 offset-sm-5">
                            <button type="submit" className="btn btn-primary">Add</button>
                            <button type="button" className="btn btn-default ml-2" onClick={onClickCancel}>Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }

    return renderForm(context.username, stations);
}

function addTrackLoader(context) {
    return async ({ request, params }) => {
        if (!context.username) {
            return;
        }

        return await context.dal.fetchStations(context.username);
    }
}

export default AddTrack;
export {
    addTrackLoader
};
