import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Context from '../context';

export default function Logout() {
    const context = useContext(Context);
    const navigate = useNavigate();
    useEffect(() => {
        console.log("navigate after logout");
        context.dal.logout().finally(() => {
            context.reset();
            navigate("/");
        });
    });
}
