/* global SC */
/* global YT */

import { createContext } from 'react';
import { runInAction, makeObservable, observable, computed } from 'mobx';

import Dal from '../dal';

class AppContext {
    dal = null;

    username = "";
    email = "";

    station = null;
    tracks = null;
    trackIndex = null;

    widget = null;
    resource = null;

    mode = ""

    constructor(dal) {
        this.dal = dal;
        if (!dal) {
            this.dal = new Dal();
        }

        makeObservable(this, {
            station: observable,
            tracks: observable,
            trackIndex: observable,
            widget: observable,
            resource: observable,
            mode: observable,
            username: observable,
            track: computed
        });
    }

    async loadLoggedInUser() {
        const data = await this.dal.getLoggedInUser();
        this.username = data.username;
        this.email = data.email;
    }

    async loadStation(username, canonical) {
        const station = await this.dal.fetchStation(username, canonical)
        this.station = station;
        this.resource = null;
    }

    async loadTracks(username, canonical) {
        const tracks = await this.dal.fetchTracks(username, canonical);
        this.tracks = tracks;
    }

    async loadTrack(resource) {
        const oembed = await this.dal.fetchResource(resource);
        this.resource = oembed;
    }

    get track() {
        if (!this.tracks) {
            return;
        }

        return this.tracks[this.trackIndex];
    }

    reset() {
        runInAction(() => {
            this.station = null;
            this.tracks = null;
            this.trackIndex = null;
            this.widget = null;
            this.resource = null;
            this.mode = "";
            this.username = "";
            this.email = "";
        });
    }

    setPlaylistIndex(index, updateHash = true) {
        this.trackIndex = index;

        if (!this.tracks) {
            return;
        }

        let track = this.tracks[this.trackIndex];
        if (!track) {
            return;
        }

        if (updateHash) {
            window.location.hash = (this.trackIndex + 1);
        }

        this.dal.fetchResource(track.resource).then(value => {
            this.resource = value;
        }).catch(err => {
            this.next();
        });
    }

    bindWidget(iframe) {
        if (!iframe) {
            return;
        } else if (this.resource.provider_name == "SoundCloud") {
            this.widget = this.handleSoundCloud(iframe);
        } else if (this.resource.provider_name == "YouTube") {
            this.widget = this.handleYouTube(iframe);
        }
    }

    handleSoundCloud(iframe) {
        var widget = SC.Widget(iframe);
        widget.bind(SC.Widget.Events.FINISH, () => {
            widget.unbind(SC.Widget.Events.FINISH);
            setTimeout(this.next(), 1000);
        });

        return widget;
    }

    handleYouTube(iframe) {
        return new YT.Player(iframe, {
            events: {
                "onStateChange": (event) => {
                    switch (event.data) {
                        case 0: // ended
                            setTimeout(this.next(), 1000);
                            break;
                    }
                }
            }
        });
    }

    play() {
        runInAction(() => {
            this.mode = "play";
        });
    }

    stop() {
        runInAction(() => {
            this.mode = "";
            this.widget = null;
        });
    }

    next() {
        let nextIndex = this.trackIndex + 1;
        if (nextIndex >= this.tracks.length) {
            nextIndex -= this.tracks.length;
        }
        
        this.setPlaylistIndex(nextIndex);
    }
    
    prev() {
        let nextIndex = this.tracks - 1;
        if (nextIndex < 0) {
            nextIndex += this.tracks.length;
        }
        
        this.setPlaylistIndex(nextIndex);
    }
}

export default createContext();
export {
    AppContext
};