import React, { useContext } from 'react';
import { Link, NavLink, useMatches } from 'react-router-dom';

import { observer } from 'mobx-react';

import Context from '../context';

function Navbar() {
    const context = useContext(Context);
    const matches = useMatches();
    const stationActive = matches.findIndex(match => match?.id === "station") !== -1;

    function renderLinks(username) {
        if (username) {
            return (
                <React.Fragment>
                    <li className="nav-item"><NavLink to="catalog" className="nav-link">Catalog</NavLink></li>
                    <li className="nav-item"><NavLink to="stations" className="nav-link">Stations</NavLink></li>
                </React.Fragment>
            );
        } else {
            return (
                <li className="nav-item"><NavLink to="catalog" className="nav-link">Catalog</NavLink></li>
            );
        }
    }

    function renderMenu(username) {
        if (username) {
            return (
                <React.Fragment>
                    <li className="nav-item"><NavLink className="nav-link" to={`/users/${username}`}>{username}</NavLink></li>
                    <li className="nav-item"><Link className="nav-link" to="logout">Logout</Link></li>
                </React.Fragment>
            )
        } else {
            const options = {
                target: stationActive ? "_blank" : ""
            };
            return (
                <li className="nav-item"><NavLink className="nav-link" to="/login" {...options}>Login</NavLink></li>
            )
        }
    }

    function render() {
        const username = context.username;
        return (
            <nav className="navbar navbar-expand-sm navbar-light bg-faded">
                <div className="container-fluid">
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <Link to="/" className="navbar-brand">Friends Radio</Link>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-lg-0">
                            {renderLinks(username)}
                        </ul>
                        <ul className="navbar-nav ms-auto mb-lg-0">
                            {renderMenu(username)}
                        </ul>
                    </div>
                </div>
            </nav>
        );
    }

    return render();
}

export default observer(Navbar);
