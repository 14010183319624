import React, { Component } from 'react';

import { Link } from 'react-router-dom';

function Header(props) {

    function ownerAddons(isOwner) {
        if (isOwner) {
            return (
                <button type="button" className="btn btn-link pl-0"><Link to={`/stations/${station.User.username}/${station.canonical}/tracks`} target="_blank">Add track</Link></button>
            )
        } else {
            return;
        }
    }

    const { station, username } = props;
    const isOwner = station.User.username === username;
    return (
        <li className="media">
            <div className="media-body d-flex flex-column align-items-start" style={{width: "100%", overflowWrap: "anywhere"}}>
                <h4>{station.name}</h4>
                { ownerAddons(isOwner) }
            </div>
        </li>
    );
}

export default Header;
