import { Outlet } from 'react-router-dom';

import Navbar from './Navbar';

export default function Container() {
    return (
        <div id="App-container" className="d-flex flex-column">
            <div style={{ flexGrow: 0 }}>
                <Navbar />
            </div>
            <Outlet />
        </div>
    );
}
