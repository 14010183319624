import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Context from '../context';

class Widget extends Component {
    constructor(props) {
        super(props);

        this.onClickThumbnail = this.onClickThumbnail.bind(this);
        this.onThumbnailLoad = this.onThumbnailLoad.bind(this);
    }

    onClickThumbnail(e) {
        this.context.play();
    }

    onThumbnailLoad(e) {
        const element = e.currentTarget;
        const node = element.parentNode.parentNode.parentNode.parentNode.parentNode;
        if (node) {
            node.scrollTop = node.scrollHeight - node.clientHeight;
        }
    }

    widgetReference(element) {
        if (!element || !element.childNodes[0]) return;
        this.context.bindWidget(element.childNodes[0]);
    }

    renderWidget(resource, preview) {
        if (preview) {
            return (
                <button type="button" className="btn btn-link" onClick={this.onClickThumbnail}>
                    <img className="img-thumbnail trackPreview" src={resource.thumbnail_url} onLoad={this.onThumbnailLoad} alt="Play track"></img>
                </button>
            );
        } else {
            const responsive = resource.provider_name === "YouTube" ? "embed-responsive embed-responsive-16by9" : "";
            return (
                <div className={responsive} dangerouslySetInnerHTML={{ __html: resource.html }} ref={this.widgetReference.bind(this)}></div>
            );
        }
    }

    render() {
        const { resource, track } = this.props;
        if (!resource) {
            return <p>Loading resource</p>;
        } else if (!track) {
            return <p>Loading track</p>
        }

        const preview = this.context.mode !== "play";
        return (
            <div>
                <div className="d-flex justify-content-between">
                    <h3><a href={track.resource}>{resource.title}</a></h3>
                </div>
                <div className="widget">
                    {this.renderWidget(resource, preview)}
                </div>
            </div>
        );
    }
}

Widget.contextType = Context;

export default observer(Widget);