import { createRoot } from 'react-dom/client';

import App from './ui/App';
import { AppContext } from './context';

const context = new AppContext();

context.loadLoggedInUser().finally(() => {
    const { app } = App({ context });
    createRoot(document.getElementById('root')).render(app);
});

document.onvisibilitychange = (event) => {
    context.loadLoggedInUser();
};

function updateWindowHash() {
    if (window.location.hash.length < 1) {
        return;
    }

    const index = parseInt(window.location.hash.substring(1), 10) - 1;
    if (index != null && !isNaN(index) && index !== context.trackIndex) {
        context.setPlaylistIndex(index, false);
    }
}

window.onhashchange = (e) => {
    updateWindowHash();
};

updateWindowHash();